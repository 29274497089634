import { Controller } from "@hotwired/stimulus";
import { i18n } from "../components/locales_handler"


export default class extends Controller {
  static targets = [
    'entityType', 'entitlementListPartial', 'entitlementTemplate', 'nestedFormContainer',
    "searchEntitlementsField", "entitlementModal", "borwseBtn", "EntitlementModalTitle",
    "entitlementsList"
  ];

  connect() {
    this.entityType = "";
    this.setDefaultSelectedOption();
    this.setModalTitle();
    this.initializeEntityTypeOnSearch();
    this.debouncedFetchAndAppendHtml = this.debounce(this.fetchAndAppendHtml.bind(this), 1000);
  }

  static values = {
    wrapperSelector: { type: String, default: ".nested-form-wrapper" },
  };


  debounce(func, wait) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, arguments), wait);
    };
  }

  initializeEntityTypeOnSearch() {
    const searchCollection = i18n.t('coupon.search_collections');
    const searchProduct = i18n.t('coupon.search_products');

    this.searchEntitlementsFieldTargets.forEach(field => {
      field.dataset.entityType = this.entityType;
      var placeholder = this.entityType === 'Collection' ? searchCollection : searchProduct;
      field.placeholder = placeholder
    });
  }

  async setDefaultSelectedOption() {
    const selectedOption = Array.from(this.entityTypeTarget.options).find(option => option.selected);
    this.entityType = selectedOption.text;
    await this.fetchEntitlements();
  }

  setDefaultEntityType(event) {
    this.resetEntityType();
    const selectedOption = this.getSelectedOption(event);
    this.entityType = selectedOption.textContent;

    this.initializeEntityTypeOnSearch();
    this.fetchEntitlements();
    this.updateEntitlementsList(selectedOption.textContent);
    this.setModalTitle();
  }

  updateEntitlementsList(selectedEntityType) {
    Array.from(this.entitlementsListTargets).forEach(table => {
      const rows = Array.from(table.querySelectorAll("tr"));
      const isMatchingTable = table.dataset.entityType === selectedEntityType;
      const hasMatchingRow = rows.some(
        row => row.dataset.entityType === selectedEntityType
      );

      table.style.display = isMatchingTable || hasMatchingRow ? "inline-table" : "none";

      rows.forEach(row => {
        const hiddenInput = row.querySelector(".hidden-destroy-input");
        if (hiddenInput) hiddenInput.value = "true";
      });
    });
  }


  resetEntityType() {
    Array.from(this.entityTypeTarget.options).forEach(option =>
      option.removeAttribute("selected")
    );
  }

  getSelectedOption(event) {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    selectedOption.setAttribute("selected", "selected");
    return selectedOption;
  }


  initializeEntitlements() {
    const modalEntities = this.entitlementListPartialTarget.querySelectorAll('table tbody tr');
    const existingEntitlements = Array.from(this.entitlementsListTarget.querySelectorAll('tr')).map(row => ({
      entityId: row.dataset.entityId,
      entityType: row.dataset.entityType
    }));

    const findMatchingProperties = (set, properties) =>
      set.filter(entry =>
        Object.keys(properties).every(key => entry[key] === properties[key])
      );

    modalEntities.forEach(row => {
      const Entity = {
        entityId: row.dataset.entityId,
        entityType: this.entityType
      }
      var matchingEntitlement = findMatchingProperties(existingEntitlements, Entity)
      if (matchingEntitlement.length > 0) {
        row.style.display = 'none';
      }
    });
  }

  openEntitlementModal(event) {
    this.resetSearchFields();
    this.setModalTitle();
    this.borwseBtnTarget.click();

    if (!this.entitlementModalTarget.classList.contains('hidden')) {
      const input = this.entitlementModalTarget.querySelector('input');
      input.value = event.target.value;
      input.focus();
    }
  }

  resetSearchFields() {
    this.searchEntitlementsFieldTargets.forEach(field => field.value = "");
  }

  setModalTitle() {
    let title = this.entityType === "Collection"
      ? i18n.t('coupon.manage_collections')
      : i18n.t('coupon.manage_products');
    this.EntitlementModalTitleTarget.innerText = title;
  }

  search(event) {
    const searchFormControllerElement = document.querySelector('[data-controller="search-form"]');
    const searchFormController = this.application.getControllerForElementAndIdentifier(
      searchFormControllerElement,
      'search-form'
    );

    const url = searchFormController.search(event)
    url && this.debouncedFetchAndAppendHtml(url, this.entitlementListPartialTarget);
  }

  async fetchEntitlements() {
    return this.fetchAndAppendHtml(
      `/admin/entitlements?entity_type=${this.entityType}`,
      this.entitlementListPartialTarget
    );
  }

  add(event) {
    event.preventDefault();

    const tableRow = event.target.closest('tr')
    const wrapper = this.createWrapper(tableRow)
    const entitlementRows = this.entitlementsListTarget.querySelectorAll('table tbody tr');
    const entityId = event.target.closest('tr').dataset.entityId

    const matchedEntitlement = Array.from(entitlementRows).find(entitlement => {
      return  entitlement.dataset.entityId === entityId && this.entityType == entitlement.dataset.entityType
    });

    if (matchedEntitlement) {
      matchedEntitlement.style.display = '';
      const input = matchedEntitlement.querySelector("input[name*='_destroy']");
      if (input) input.value = false;
      tableRow.style.display = "none";
      return
    }

    wrapper.querySelector('table').setAttribute('data-record-type', this.entityType);

    wrapper.querySelector('#entity-type-hidden').value = this.entityTypeTarget.options[this.entityTypeTarget.selectedIndex].text
    const entitlementField = wrapper.querySelector('#entitlement-id-field');

    entitlementField.value = this.getEntitlementId(event)
    tableRow.style.display = "none";
    this.nestedFormContainerTarget.insertAdjacentHTML("beforebegin", wrapper.innerHTML);
  }

  remove(event) {
    event.preventDefault();

    const browseableEntitlementsPartial = this.entitlementListPartialTarget
    const entitlementRows = browseableEntitlementsPartial.querySelectorAll('table tbody tr');
    const wrapper = event.target.closest(this.wrapperSelectorValue);

    const isNewRecord = this.isNewRecord(wrapper);
    this.manageEntitlementVisibility(wrapper, entitlementRows, isNewRecord);
  }

  manageEntitlementVisibility(wrapper, entitlementRows, isNewRecord) {
    const entityId = wrapper.querySelector('td #entitlement-id-field')?.value;
    const matchedEntitlement = Array.from(entitlementRows).find(
      entitlement => entitlement.dataset.entityId === entityId
    );

    if (matchedEntitlement) matchedEntitlement.style.display = '';

    if (isNewRecord) {
      const wrapperParent = wrapper.closest('table');
      wrapperParent.remove();
    } else {
      wrapper.style.display = 'none';
      const input = wrapper.querySelector("input[name*='_destroy']");
      if (input) input.value = true;
    }
  }

  createWrapper(tableRow) {
    const content = this.entitlementTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    );
    const wrapper = document.createElement("div");
    wrapper.innerHTML = content;

    const elementsToClone = ['details', 'title', 'img' ];
    elementsToClone.forEach(type => {
      const clonedElement = tableRow.querySelector(`.entitlement-${type}`)?.cloneNode(true);
      if (clonedElement) wrapper.querySelector('tr').insertBefore(clonedElement, wrapper.querySelector('td'));
    });

    return wrapper;
  }

  getEntityTypeText() {
    return this.entityTypeTarget.options[this.entityTypeTarget.selectedIndex].text;
  }

  getEntitlementId(event) {
    let getEntityType = this.entityType === "Product"
    ? event.target.dataset.productId
    : event.target.dataset.collectionId;

    return getEntityType;
  }

  isNewRecord(wrapper) {
    return wrapper.dataset.newRecord === "true";
  }

  fetchAndAppendHtml(url, targetElement) {
    if (!targetElement) return;

    this.showLoadingState(targetElement);

    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/html" }
    })
      .then(response => response.text())
      .then(html => {
        if (targetElement) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          targetElement.innerHTML = "";
          targetElement.innerHTML += doc.body.innerHTML;
          this.initializeEntitlements();
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  showLoadingState(element) {
    element.innerHTML = ""
    element.innerHTML = `
      <div style="text-align: center; padding: 3rem;">
      ${i18n.t('loading')}
      </div>
    `;
  }
}
