import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menuItemSelect", "nameField"];

  connect() {
    this.initializeSelect2();
  }

  updateNameField(event) {
    const selectElement = event.target;
    const nameField = selectElement.closest("ul").querySelector('[data-menu-items-target="nameField"]');
    const selectedKey = selectElement.options[selectElement.selectedIndex].text;

    if (nameField && !nameField.value.trim()) {
      nameField.value = selectedKey;
    }

    this.onInput(selectElement);
  }

  onInput(selectElement) {
    selectElement.nextSibling.querySelector('.select2-selection__clear').style.display = 'none';
  }

  reinitializeSelect2() {
    setTimeout(() => {
      this.initializeSelect2();
    }, 100);
  }

  initializeSelect2(event) {
    const selectElements = this.element.querySelectorAll('.select2');

    selectElements.forEach(selectElement => {
      $(selectElement).select2({
        tags: true,
        placeholder: "Select an option or enter a URL",
        allowClear: true,
      });

      $(selectElement).on("select2:open", () => {
        const select2Input = document.querySelector(".select2-search__field");

        if (select2Input) {
          select2Input.addEventListener("input", (e) => {
            this.onInput(selectElement, e);
          });
        }

        setTimeout(() => {
          this.filterSelectOptions(selectElement);
        }, 10);
      });

      $(selectElement).on("change", this.updateNameField.bind(this));

      const clearButtons = this.element.querySelectorAll(".select2-selection__clear");
      clearButtons.forEach((button) => {
        button.style.display = "none";
      });
    });
  }

  filterSelectOptions(selectElement) {
    const predefinedOptions = ['/', '/store_front/products', '/store_front/collections', '/store_front/blogs' ];
    const options = selectElement.querySelectorAll('option');

    options.forEach(option => {
      if (!predefinedOptions.includes(option.value)) {
        const optionsList = document.querySelector('.select2-results__options');
        const selectedOption = Array.from(optionsList.querySelectorAll('li')).find(li => li.textContent.trim() === option.value.trim());

        if (selectedOption) {
          selectedOption.style.display = 'none';
        }
      }
    });
  }
}
