import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "metaDetails", "blogDescription", "blogMetaTitle", "blogVisitUrl",
    "pageTitle", "blogTitleField", "blogMetaDescription", "metaDescription",
    "blogSlug"
  ];

  connect() {
    this.initializePlaceholders();
    this.initializeMetaTitle();
    this.updateBlogVisitUrlOnEdit();

    setTimeout(() => this.adjustEditorHeight(), 1000);
  }

  initializePlaceholders() {
    this.pageTitleTarget.placeholder = this.blogTitleFieldTarget.value.trim();
    this.blogMetaDescriptionTarget.innerHTML = this.metaDescriptionTarget.value.trim();
  }

  initializeMetaTitle() {
    const pageTitle = this.pageTitleTarget.value.trim();
    this.blogMetaTitleTarget.innerText = pageTitle || this.blogTitleFieldTarget.value.trim();
  }

  adjustEditorHeight() {
    const editorElement = document.querySelector("[data-controller='tinymce'] .tox-tinymce");
    if (editorElement) {
      editorElement.style.height = '800px';
    }
  }

  showMetaDetails(event) {
    event.target.classList.add('hidden');
    this.metaDetailsTarget.classList.remove('hidden');
  }

  showMetaTitle(event) {
    this.toggleMetaField();

    if (!this.pageTitleTarget.value.trim()) {
      this.blogMetaTitleTarget.innerText = event.target.value.trim();
    }

    if (event.target.dataset.view === 'new') {
      this.updateBlogVisitUrl(event);
    }

    this.pageTitleTarget.placeholder = event.target.value.trim();
  }

  inputPageTitle(event) {
    this.toggleMetaField();
    const value = event.target.value.trim();
    this.blogMetaTitleTarget.innerText = value || this.blogTitleFieldTarget.value;
  }

  changeMetaDescription(event) {
    this.blogMetaDescriptionTarget.innerHTML = event.target.value.trim();

    const fieldsStatus = this.getFieldsStatus();
    this.updateDescriptionVisibility(fieldsStatus);
  }

  toggleMetaField() {
    const fieldsStatus = this.getFieldsStatus();

    this.blogMetaTitleTarget.classList.toggle('hidden', !fieldsStatus.titleOrMeta);
    this.blogVisitUrlTarget.classList.toggle('hidden', !fieldsStatus.titleOrMeta);
    this.blogMetaDescriptionTarget.classList.toggle('hidden', fieldsStatus.metaDescription);

    if (!fieldsStatus.titleOrMeta && fieldsStatus.metaDescription) {
      this.updateDescriptionText('title');
    } else if (!fieldsStatus.titleOrMeta) {
      this.updateDescriptionText('title and description');
    }

    this.blogDescriptionTarget.classList.toggle('hidden', fieldsStatus.titleOrMeta);
  }

  updateBlogVisitUrlOnEdit() {
    const userValue = this.blogSlugTarget.value.trim();
    this.updateBlogVisitUrlText(userValue);
  }

  updateBlogVisitUrl(event) {
    const userValue = event?.target?.value?.trim() || this.blogTitleFieldTarget.value.trim()
    this.updateBlogVisitUrlText(userValue);
  }

  updateBlogVisitUrlText(userValue) {
    const baseUrl = this.blogVisitUrlTarget.innerText.replace(/\/(blogs).*$/, '');
    const slug = userValue ? `/${this.slugify(userValue)}` : '';
    this.blogVisitUrlTarget.innerText = `${baseUrl}/blogs${slug}`;
  }

  getFieldsStatus() {
    return {
      titleOrMeta: !!(this.blogTitleFieldTarget.value.trim() || this.pageTitleTarget.value.trim()),
      metaDescription: !!this.metaDescriptionTarget.value.trim()
    };
  }

  updateDescriptionVisibility({ titleOrMeta, metaDescription }) {
    if (!metaDescription) {
      this.updateDescriptionText('title and description');
    } else {
      this.updateDescriptionText('title');
    }

    this.blogDescriptionTarget.classList.toggle('hidden', titleOrMeta);
    this.blogMetaDescriptionTarget.classList.toggle('hidden', !titleOrMeta);
  }

  updateDescriptionText(fields) {
    this.blogDescriptionTarget.innerText = this.setSeoGuideMessage(fields);
  }

  slugify(text) {
    return text.replace(/[^\w\s]/gi, '').toLowerCase().split(" ").join("-");
  }

  setSeoGuideMessage(fields) {
    return `Add ${fields} to see how this Blog post might appear in a search engine listing`;
  }
}
